<template>
    <div class="p-server-list" v-loading="!ready">
        <div class="hd">
            <div class="add-wrap" v-show="false">
                <el-button
                    class="add"
                    type="primary"
                    icon="el-icon-circle-plus-outline"
                    @click="onAddButtonClick"
                    >新增</el-button
                >
            </div>
        </div>
        <div class="bd">
            <el-table :data="list">
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="ip" label="IP"></el-table-column>
                <el-table-column label="服务商">
                    <template scope="scope">
                        {{ scope.row.provider }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="ft"></div>
    </div>
</template>

<style lang="less"></style>

<script>
export default {
    components: {},
    data() {
        return {
            ready: false,
            isLoading: false,
            list: [],
        };
    },
    computed: {},
    mounted() {
        let that = this;
        that.getList().then(() => {
            that.seed();
            that.ready = true;
        });
    },
    methods: {
        getList() {
            let that = this;
            return new Promise((resolve, reject) => {
                that.$ajax("/server/list", {})
                    .done(function (ret) {
                        that.list = ret;
                        resolve();
                    })
                    .fail(function () {
                        reject();
                    })
                    .always(function () {
                        //
                    });
            });
        },
        onAddButtonClick() {
            this.$alert("尚未开发好");
            // this.$router.push({ name: 'server-add' })
        },
        async seed() {
            let that = this;
            let arr = [
                { name: "mmlsz", ip: "120.77.33.228", provider: "aliyun" },
                { name: "hk1", ip: "47.244.101.107", provider: "aliyun" },
                { name: "mmler", ip: "120.76.61.245", provider: "aliyun" },
                { name: "git2", ip: "8.129.55.123", provider: "aliyun" },
                { name: "dy", ip: "45.79.104.131", provider: "linode" },
                { name: "tadly", ip: "45.79.97.114", provider: "linode" },
                { name: "jp1", ip: "139.162.127.52", provider: "linode" },
                { name: "git", ip: "139.162.116.129", provider: "linode" },
                { name: "usa1", ip: "45.33.34.122", provider: "linode" },
                { name: "usa2", ip: "198.74.49.77", provider: "linode" },
                { name: "usa3", ip: "45.79.105.251", provider: "linode" },
                { name: "usa4", ip: "45.79.70.134", provider: "linode" },
                { name: "usa5", ip: "45.79.79.201", provider: "linode" },
                { name: "usa7", ip: "50.116.1.174", provider: "linode" },
                { name: "usa8", ip: "192.155.85.33", provider: "linode" },
                { name: "usa6", ip: "34.209.42.115", provider: "aws" },
                { name: "imgbk", ip: "35.247.185.85", provider: "google" },
            ];
            let count = 0;
            for (let i = 0; i < arr.length; i++) {
                let server = arr[i];
                let found = that.list.find((item) => item.ip === server.ip);
                if (found) {
                    continue;
                }
                count++;
                await new Promise((resolve) => {
                    that.$ajax("/server/add", server)
                        .done(function () {
                            resolve();
                        })
                        .fail(function () {
                            //
                        })
                        .always(function () {
                            //
                        });
                });
            }
            if (count > 0) {
                window.location.reload();
            }
        },
    },
    filters: {},
};
</script>
